import React, { Component } from 'react';
import '../styles/MultiStepForm.css'; // Import the custom CSS file for styling
import { Modal, Table, Button } from 'react-bootstrap';

class SessionModal extends Component {
  render() {
    const { showModal, availableSessions, onHide, onSelect, selectedSession } = this.props;
    return (
      <Modal show={showModal} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select a Session</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-table">
          <Table striped bordered hover >
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Session Number</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {availableSessions.map((session, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{session.label}</td>
                  <td>{session.date}</td>
                  <td>
                    <Button
                      variant="secondary"
                      onClick={() => onSelect(session.value, session.label)}
                      disabled={selectedSession.value === session.value}
                    >
                      Select
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SessionModal;
