import React, { Component } from 'react';
import '../styles/MultiStepForm.css';
import { default as ReactSelect } from "react-select";
import { Col, Row, Button } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import { REPORTINGMETRICS, PLOTTINGMETRICS } from "../data/StaticMetricInfo.js";
import { components } from "react-select";
import { FaTable } from 'react-icons/fa';
import ReportSessionModal from './ReportSessionModal';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import getApiUrl from './config.js';
import MetricPlotsModal from './MetricPlotsModal';
const apiUrl = getApiUrl();

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    color: '#888',
  },
};

function CustomButton({ children, onClick }) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Button variant="outline-dark" className="custom-button" onClick={handleClick}>
      {children}
    </Button>
  );
}

export default class MultiStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
     generatedReport: "",
     selectedMetric: "",
     metricOperation: "",
     requestedReport: false,
     reportLoading: false,
     selectedStartDate: null,
     selectedEndDate: null,
     requestedReportSessions: false,
     searchedReportSessions: [],
     reportSessionID: "",
     showModal: false,
     requestedPlot: false,
     plotLoading: false,
     plotsX: [],
     plotsY: [],
    };
    this.handleMetricOperation = this.handleMetricOperation.bind(this);
    this.generate_report = this.generate_report.bind(this);
    this.generate_plot = this.generate_plot.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleReportSessionModal = this.handleReportSessionModal.bind(this);
    this.find_report_sessions = this.find_report_sessions.bind(this);
  }

  handleMetricOperation = (e) => {
    this.setState({
      metricOperation: e.target.value,
      generatedReport: "",
      selectedMetric: "",
      requestedReport: false,
      reportLoading: false,
      selectedStartDate: null,
      selectedEndDate: null,
      requestedReportSessions: false,
      searchedReportSessions: [],
      reportSessionID: "",
      showModal: false,
      requestedPlot: false,
      plotLoading: false,
      plotsX: [],
      plotsY: [],
    });
  };

  handleChange = (selectedOption, { name }) => {
    this.setState({ [name]: selectedOption });
  };

  handleDateChange = (dateType) => (event) => {
    const value = event.target.value;
    this.setState({
      [dateType]: value,
    });
  };

  handleReportSessionModal = (selectedValue, selectedLabel) => {
    this.setState({
      reportSessionID: {
        value: selectedValue,
        label: selectedLabel,
      },
      showModal: false, // Close the modal after selection
    });
  };

  // API call to the backend for generating the report 
  // dont forget: set up backend api call to fetch the requested report from the S3 Bucket as an html file
  generate_report = () => {
    // Resetting the states so that the previously loaded reports are removed
    this.setState({
      generatedReport: "",
    });
    
    // if no selected metric or report metric operation not selected 
    if (this.state.selectedMetric == '') {
      alert("Please select a metric first!")
    }
    else if(this.state.metricOperation != "report"){
      alert("Please select metric report if you wish to generate a report!")
    }
    else {
      this.setState({
        requestedReport: true,
        reportLoading: true
      });
      // send the selected report session ID (this is the name and date?) to the backend
      const dataToSend = {
        selectedMetric: this.state.selectedMetric,
        reportSessionID: this.state.reportSessionID,
      };
      
      axios.get(apiUrl + '/api/metric-report', { params: dataToSend }) 
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              generatedReport: response.data.generatedReport,
            });
            this.setState({
              reportLoading: false
            })
          }
          else {
            this.setState({
              reportLoading: false
            })
            alert("Unable to generate the report. Check provided details.")
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };

  // API call to the backend for retrieving metric reports
  find_report_sessions(){
    if (this.state.metricOperation != "report"){
      alert("Please select metric report if you wish to fetch a report!");
      return;
    }
    if (this.state.selectedMetric != "") {
      if (this.state.selectedStartDate === null && this.state.selectedEndDate === null) {
        alert("Atleast one date is required!")
      }
      else if (this.state.selectedStartDate > this.state.selectedEndDate) {
        alert("Start date cannot be greater than end date!")
      }
      else {
        this.setState({
          requestedReportSessions: true,
        });
        const dataToSend = {
          selectedMetric: this.state.selectedMetric,
          selectedStartDate: this.state.selectedStartDate,
          selectedEndDate: this.state.selectedEndDate
        };

        axios.get(apiUrl + '/api/metric-findReportSessions', { params: dataToSend })
          .then(response => {
            if (response.data.status === "success") {
              // Extract values from each dictionary and set the state (label should be "Metric#" and date should be the date portion of the name)
              const extractedValues = response.data.availableReportSessions.map(session => ({
                value: session.value,
                label: session.label,
                date: session.date,
              }));

              // Handle the received data
              this.setState({
                searchedReportSessions: extractedValues,
                requestedReportSessions: false
              });
              
              // if no sessions could be found, alert the user
              if(extractedValues.length == 0){
                alert("No Metric Reports Found")
              }
            }
            else {
              this.setState({
                requestedReportSessions: false
              });
              alert("Unable to find any metric report. Check provided details.")
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    }
    else {
      alert("A Metric and atleast one Date are necessary for searching metric reports!")
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }));
  };

  generate_plot = () => {
    // Resetting the states so that the previously loaded plots are removed
    this.setState({
      plotsX: [],
      plotsY: [],
    });
    // if no selected metric or report metric operation not selected 
    if (this.state.selectedMetric == '') {
      alert("Please select a metric first!")
    }
    else if(this.state.metricOperation != "plot"){
      alert("Please select metric plot if you wish to generate a plot!")
    }
    else {
      this.setState({
        requestedPlot: true,
        plotLoading: true
      });
      // send the selected metric to the backend
      const dataToSend = {
        selectedMetric: this.state.selectedMetric,
      };
      
      axios.get(apiUrl + '/api/metric-plot', { params: dataToSend }) 
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              plotsX: response.data.data_x,
              plotsY: response.data.data_y,
            });
            this.setState({
              plotLoading: false
            })
          }
          else {
            this.setState({
              plotLoading: false
            })
            alert("Unable to generate the plot. Check provided details.")
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }


  render() {
    return (
      <div>
        <h1 class="page-header"><center>Metrics Page</center></h1>
        <p style={styles.message}>This Metrics page is currently under development. Plotting and Reporting are not yet fully implemented.</p>
        <br></br>

        <div className="form-wrapper">
          <Row className='mt-1'>
            <Col sm={5}>
              <Form.Label class="field-header">Select Metric Operation</Form.Label>
            </Col>
            <Col sm={2}>
              <Form.Check
                value="report"
                type="radio"
                className="field-body"
                aria-label="radio 7"
                label="Report"
                onChange={this.handleMetricOperation}
                checked={this.state.metricOperation === "report"}
              />
            </Col>
            <Col sm={2}>
              <Form.Check
                value="plot"
                type="radio"
                className="field-body"
                aria-label="radio 8"
                label="Plot"
                onChange={this.handleMetricOperation}
                checked={this.state.metricOperation === "plot"}
              />
            </Col>
          </Row>

          {(this.state.metricOperation === "report") ?
          <div>
          <Row className='mt-1'>
            <Col sm={5}>
              <Form.Label class="field-header">Metric Selection Mode</Form.Label>
            </Col>
            <Col sm={7}>
                <Row>
                  <Col sm={4}>
                    <Form.Label class="field-header">Select Metrics</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={10}>
                    {(
                      <span style={{ width: "100%" }}
                        class="d-inline-block"
                        data-toggle="popover"
                        data-trigger="focus"
                      >
                        <ReactSelect
                          isDisabled={this.state.metricOperation === ""}
                          noOptionsMessage={() => "No metrics available!"}
                          options={REPORTINGMETRICS}
                          className="field-body"
                          closeMenuOnSelect={true}
                          hideSelectedOptions={true}
                          components={{
                            Option
                          }}
                          onChange={(selectedOption) => this.handleChange(selectedOption, { name: "selectedMetric" })}
                          allowSelectAll={false}
                          value={this.state.selectedMetric}
                        />
                      </span>
                    )}
                  </Col>
                </Row>
            </Col>
            <p></p>
          </Row>
        
          <Row>
            <Col sm={5}>
            </Col>
            <Col sm={4}>
              <Form.Label class="field-header">Select Date Range</Form.Label>
            </Col>
          </Row>
          
          <Row>
            <Col sm={5}>
            </Col>
            <Col sm={7}>
              <div>
                <Row>
                  <Col sm={3}>
                    <Form.Control
                      className="field-body"
                      type="date"
                      value={this.state.selectedStartDate}
                      onChange={this.handleDateChange('selectedStartDate')}
                    />
                  </Col>

                  <Col sm={3}>
                    <Form.Control
                      className="field-body"
                      type="date"
                      value={this.state.selectedEndDate}
                      onChange={this.handleDateChange('selectedEndDate')}
                    />
                  </Col>

                  <Col sm={2}>
                  </Col>

                  <Col sm={3}>
                    {this.state.requestedReportSessions == true && (
                      <Spinner style={{
                        marginTop: 5,
                        marginLeft: 65
                      }}
                        as="span"
                        animation="grow"
                        size="lg"
                        variant="dark"
                        role="status"
                        aria-hidden="true"
                      />)}

                    {(this.state.requestedReportSessions == false && this.state.searchedReportSessions.length > 0) ?

                      <span style={{ width: "100%" }}
                        class="d-inline-block"
                        data-toggle="popover"
                        data-trigger="focus"
                      >
                        <ReactSelect
                          noOptionsMessage={() => "No reports founding within the specified date range!"}
                          options={this.state.searchedReportSessions}
                          className="field-body"
                          closeMenuOnSelect={true}
                          hideSelectedOptions={true}
                          components={{
                            Option
                          }}
                          onChange={(selectedOption) =>
                            this.handleChange(selectedOption, { name: "reportSessionID" })
                          }
                          allowSelectAll={false}
                          value={this.state.reportSessionID}
                        />
                      </span>

                      : null}
                  </Col>
                  {(this.state.requestedReportSessions == false && this.state.searchedReportSessions.length > 0) ?
                    <Col sm={1}>
                      <div className="table-icon" onClick={this.toggleModal}>
                        <FaTable />
                      </div>

                      <ReportSessionModal
                        showModal={this.state.showModal}
                        availableReportSessions={this.state.searchedReportSessions}
                        onHide={this.toggleModal}
                        onSelect={this.handleReportSessionModal}
                        selectedReportSession={this.state.reportSessionID}
                      />

                    </Col> : null}
                    <p></p>
                </Row>
                <Row>
                  <Col md={4} style={{ marginTop: 5 }}>
                    <CustomButton onClick={this.find_report_sessions}>Find Metric Reports</CustomButton>
                  </Col>
                </Row>
              
              </div>
            </Col>
            
          </Row>
          
          <Row>
            <Col sm={2}>
              <CustomButton onClick={this.generate_report}>Generate Report</CustomButton>
            </Col>
          </Row>
          </div>
          : null}

          {(this.state.metricOperation === "plot") ?
          <div>
          <Row className='mt-1'>
            <Col sm={5}>
              <Form.Label class="field-header">Metric Selection Mode</Form.Label>
            </Col>
            <Col sm={7}>
                <Row>
                  <Col sm={4}>
                    <Form.Label class="field-header">Select Metrics</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={10}>
                    {(
                      <span style={{ width: "100%" }}
                        class="d-inline-block"
                        data-toggle="popover"
                        data-trigger="focus"
                      >
                        <ReactSelect
                          isDisabled={this.state.metricOperation === ""}
                          noOptionsMessage={() => "No metrics available!"}
                          options={PLOTTINGMETRICS}
                          className="field-body"
                          closeMenuOnSelect={true}
                          hideSelectedOptions={true}
                          components={{
                            Option
                          }}
                          onChange={(selectedOption) => this.handleChange(selectedOption, { name: "selectedMetric" })}
                          allowSelectAll={false}
                          value={this.state.selectedMetric}
                        />
                      </span>
                    )}
                  </Col>
                </Row>
            </Col>
            <p></p>
          </Row>

          <Row>
            <Col sm={2}>
              <CustomButton onClick={this.generate_plot}>Generate Plot</CustomButton>
            </Col>
          </Row>

          
          </div>
          : null}
        </div>
        
        <div>
          {(this.state.requestedReport) ?
            <div className='form-wrapper'> {(this.state.reportLoading) ?
              <Spinner style={{
                marginTop: 200,
                marginLeft: 800,
                marginBottom: 200,
              }}
                as="span"
                animation="grow"
                size="lg"
                variant="dark"
                role="status"
                aria-hidden="true"
              /> : null}

              {(this.state.metricOperation == "report" && this.state.selectedMetric != "") ?
                <iframe srcdoc={this.state.generatedReport} border="0" margin='auto' height="400" width="1390"></iframe>: null}
          </div>
          : null}
        </div>

        <div>
          {(this.state.requestedPlot) ?
            <div className='form-wrapper'> {(this.state.plotLoading) ?
              <Spinner style={{
                marginTop: 200,
                marginLeft: 800,
                marginBottom: 200,
              }}
                as="span"
                animation="grow"
                size="lg"
                variant="dark"
                role="status"
                aria-hidden="true"
              /> : null}

              {(this.state.metricOperation === "plot" && this.state.selectedMetric != "")?
                <MetricPlotsModal plotsData={[this.state.plotsX, this.state.plotsY]} selectedMetric={this.state.selectedMetric} />
                :null}
          </div>
          : null}
        </div>
      </div>
    );
  }
}