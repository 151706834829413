import React, { Component } from 'react';
import '../styles/MultiStepForm.css'; // Import the custom CSS file for styling
import { Modal } from 'react-bootstrap';
import { default as ReactSelect } from "react-select";
import { Col, Row } from "react-bootstrap";
import { Option, CustomButton } from "./TemplatesBuilder";

// TODO: MAKE ALREADY ADDED PARAMS UNSELECTABLE (OTHERWISE USER CAN ADD THE SAME PARAM TWICE...)
//       will need to pass the parameters in template already with props and exclude them from the selectableOptions
//       THIS IS NOT URGENT AS ADDING AN ALREADY INCLUDED PARAMETER DOES NOTHING.        

// This modal will be the popup window that allows users to select a Parameter(s) to be added to the template.
class AddParameterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalParamsSelected:[],
            modalParamsSearchKeyword:"",
        };
    }

    handleParametersSelection = (selected) => {
        this.setState({
            modalParamsSelected: selected
        });        
    }

    addParameters = () => {
        // call the handleModalParametersSelection method in template builder which will remove that selected parameter in backend
        this.props.parentHandleModalParameter(this.state.modalParamsSelected)
        // Note: parentHandleModalParameterSelection will handle hiding the modal and adding the parameters selected
    }

    handleParamsListInputChange = (modalParamsSearchKeyword, e) => {
        if (e.action === 'input-change') {
          this.setState({ modalParamsSearchKeyword });
        }
    }

    render(){
        const { inputParameterOptions, showModal, onHide } = this.props;
        return(
            <Modal show={showModal} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Select Parameters</Modal.Title>
            </Modal.Header>
            <Modal.Body className="field-body">
                <div>
                    <Row className='mt-1'>
                        <Col sm={12}>
                            <span style={{ width: "100%" }}
                            class="d-inline-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            >
                            <ReactSelect
                                noOptionsMessage={() => "No parameters available!"}
                                options={inputParameterOptions.map((parameter) => ({
                                    label: parameter.parameter_name,
                                    value: parameter.parameter_id,
                                }))}
                                isMulti
                                className="field-body"
                                closeMenuOnSelect={false}
                                hideSelectedOptions={true}
                                components={{
                                    Option
                                }}
                                onChange={this.handleParametersSelection}
                                allowSelectAll={true}
                                value={this.state.modalParamsSelected}
                                onInputChange={this.handleParamsListInputChange}
                                inputValue={this.state.modalParamsSearchKeyword}
                             />
                            </span>
                        </Col>
                    </Row>
                    <Row className='mt-1'>
                        <Col sm={12}>
                            <CustomButton centered onClick={this.addParameters}>Done</CustomButton>    
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        );
    }
}
export default AddParameterModal;