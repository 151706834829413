import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import getApiUrl from '../views/config.js';
import Spinner from 'react-bootstrap/Spinner';
import { Col, Row } from "react-bootstrap";
const apiUrl = getApiUrl();


const MyFormModal = ({ isOpen, onClose }) => {
  const [subject, setSubject] = useState('');
  const [requestedSubmission, setRequestedSubmission] = useState('False');
  const [description, setDescription] = useState('');
  const [issueType, setIssueType] = useState('Bug');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      subject: subject,
      description: description,
      issueType: issueType
    };

    setRequestedSubmission('True')

    try {
      axios.get(apiUrl + '/upload-text', { params: formData })
        .then(response => {
          setRequestedSubmission('False')
          if (response.data.status === "success") {

            setSubject('');
            setDescription('');
            setIssueType('Bug');

            onClose();
          }
          else {
            alert("Unable to submit details")
          }
        })
    } catch (error) {
      console.error('Error while submitting the form:', error);
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Reporting Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="subject">
            <Form.Label>Subject:</Form.Label>
            <Form.Control type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Description:</Form.Label>
            <Form.Control as="textarea" rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="issueType">
            <Form.Label>Issue Type:</Form.Label>
            <Form.Control as="select" value={issueType} onChange={(e) => setIssueType(e.target.value)}>
              <option value="Bug">Bug</option>
              <option value="Feature">Feature</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>
          <br></br>
          <Row>
            <Col>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
            <Col>
              {(requestedSubmission == "True") ?
                <Spinner style={{
                  marginLeft: -10,
                }}
                  as="span"
                  animation="grow"
                  size="lg"
                  variant="dark"
                  role="status"
                  aria-hidden="true"
                /> : null}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MyFormModal;