import React from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'react-plotly.js';
import Spinner from 'react-bootstrap/Spinner';

class VisualizationModal extends React.Component {
  computingPlots
  constructor(props) {
    super(props);
    this.state = {
      computingPlots: true
    };
  }

  visualizeGaussian = (data) => {
    // Here we can put the gaussian model code (Fitting the data, visualizing it)
    var mu = data[0];
    var sigma = data[1];
    var linspace = data[2];
    var height = data[3];
    var timestamp = data[4];
    var threshold = data[5];
    var trace = [{
      x: linspace,
      y: height,
      mode: 'lines',
      line: {color: 'green', width: 3, shape: 'spline'},
    }];

    var threshold_line = [{
      type:'line',
      xref: 'paper',
      x0: 0,
      y0: threshold,
      x1: 1,
      y1: threshold,
      line: {
        color: '#f22416',
        width: 2,
      }
    }];

    const layout = {
      yaxis: {
        title: {
          text: "Probability Density"
        }
      },
      xaxis: {
        title: {
          text: "Value"
        }
      },
      shapes: threshold_line,
      title: ('Gaussian Distribution, signal: ' + this.props.selectedSignal + ', timestamp: ' + timestamp + ', mu: ' + mu +', sigma: '+ sigma + ', threshold: ' + threshold),
      showlegend: false
    };

    return(<div>
      <Plot style={{
        marginLeft: 40,
        }} data={trace} layout={layout} />
     </div>
    );
  }

  visualizeMinMax = () => {
    return [];
  }

  visualizeNonParam = (data_x, data_y, timestamp, threshold) => {
    var trace = [{
      x: data_x,
      y: data_y,
      mode: 'markers',
      type: 'scatter',
      marker: {
        size: 10,
        color: '#1f77dd',
      }
    }];
    // iterate through each point in trace and draw a vertical line from the point to 0 (this way we mimic the 'stem' plot)
    var vert_lines = [data_x.length];
    for(let i = 0; i < data_x.length; i++){
      vert_lines.push({
        type: 'line',
        x0: data_x[i],
        y0: 0,
        x1: data_x[i],
        y1: data_y[i],
        line: {
          color: '#1f77b4',
          width: 2,
        }
      });
    } 
    // manually add the horizontal line indicating the threshold, for now we can just push it to the vert_lines shape
    vert_lines.push({
      type:'line',
      xref: 'paper',
      x0: 0,
      y0: threshold,
      x1: 1,
      y1: threshold,
      line: {
        color: '#f22416',
        width: 2,
      }
    });

    const layout = {
      yaxis: {
        title: {
          text: "Probability"
        }
      },
      xaxis: {
        title: {
          text: "Value"
        }
      },
      shapes: vert_lines,
      title: ('Probability Mass Function, signal: ' + this.props.selectedSignal + ', timestamp: ' + timestamp + ', threshold: ' + threshold),
      showlegend: false
    };
    
    return(<div>
            <Plot style={{
              marginLeft: 40,
              }} data={trace} layout={layout} />
           </div>
    );
  }

  renderPlots = () => {
    const plots = [];
    // npPlotsData = [ [[xval1], [xval2], ...] , [[yval1], [yval2], ...] , [timestamp1, timestamp2, ...], [threshold1, threshold2,...]]
    // plot all non parametric timestamps
    if(this.props.npPlotsData.length > 0){
      for(let i = 0; i < this.props.npPlotsData[0].length; i++){
        plots.push(this.visualizeNonParam(this.props.npPlotsData[0][i], this.props.npPlotsData[1][i], this.props.npPlotsData[2][i], this.props.npPlotsData[3][i]));
      }
    }
    // plot all gaussian timestamps
    for(let i = 0; i < this.props.gaussianPlotsData.length; i++){
      plots.push(this.visualizeGaussian(this.props.gaussianPlotsData[i]));
    }
    return plots;
  };

  render() {
    return (
      <div>
        {this.renderPlots()}
      </div>
    );
  }
}

export default VisualizationModal;