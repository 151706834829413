export const Aircrafts = [
  { value: "12", label: "70012"},
  { value: "28", label: "70028"},
  { value: "34", label: "70034"},
  { value: "24", label: "70024"},
  { value: "53", label: "70053"},
  { value: "42", label: "70042"},
  { value: "6", label: "70006"},
  { value: "57", label: "70057"},
  { value: "21", label: "70021"},
  { value: "121", label: "70121"},
  { value: "107", label: "70107"},
  { value: "116", label: "70116"},
  { value: "117", label: "70117"},
  {value: "49", label: "70049"},
  {value: "51", label: "70051"},
  {value: "106", label: "70106"},
  {value: "64", label: "70064"},
  {value: "96", label: "70096"},
  {value: "91", label: "70091"},
  {value: "129", label: "70129"},
  {value: "138", label: "70138"},
  {value: "43", label: "70043"},
  {value: "23", label: "70023"},
  {value: "99", label: "70099"},
  {value: "66", label: "70066"},
  {value: "20", label: "70020"},
  {value: "74", label: "70074"},
  {value: "118", label: "70118"},
  {value: "43", label: "70043"},
  {value: "151", label: "70151"},
  {value: "7", label: "70007"},
  {value: "30", label: "70030"},
  {value: "46", label: "70046"},
  {value: "62", label: "70062"},
  {value: "76", label: "70076"},
  {value: "79", label: "70079"},
  {value: "88", label: "70088"}
];


export const ATA = [
  { value: "ata32", label: "ATA 32" },
  { value: "ata27", label: "ATA 27" },
  { value: "ata24", label: "ATA 24" },
  { value: "ata28", label: "ATA 28" },
  { value: "ata31", label: "ATA 31" },
  { value: "ata34", label: "ATA 34" }
];


export const Phases = [
  { value: "LANDING_1", label: "LANDING-1" },
  { value: "LANDING_2", label: "LANDING-2" },
  { value: "LANDING_3", label: "LANDING-3" },
  { value: "LANDING_4", label: "LANDING-4" },
  { value: "TAKEOFF_1", label: "TAKEOFF-1" },
  { value: "TAKEOFF_2", label: "TAKEOFF-2" },
  { value: "TAKEOFF_3", label: "TAKEOFF-3" },
  { value: "PRETAKEOFF_1", label: "PRETAKEOFF-1" },
  { value: "PRETAKEOFF_2", label: "PRETAKEOFF-2" },
  { value: "PRETAKEOFF_3", label: "PRETAKEOFF-3" },
  { value: "TAXI_1", label: "TAXI-1" },
  { value: "TAXI_2", label: "TAXI-2" },
  { value: "TAXI_3", label: "TAXI-3" },
];