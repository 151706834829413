import React from 'react';
import DataLoaderPageImage from '../images/BaseScreenshotDataLoader.png'
import DataBucketImage from '../images/DataBucketDataLoader.png'
import ParameterSelectionImage from '../images/ParamSelectionDataLoader.png'
import TemplateSelectionImage from '../images/ParamSelectionTemplateDataLoader.png'
import SessionDetailsSelectionImage from '../images/extractModeDataLoader.png'
import S3URLInputImage from '../images/s3URLExtractionDataLoader.png'
import SessionSearchDateRangeImage from '../images/sessionSearchDataLoader.png'
import AvailableSessionSelectionImage from '../images/availableSessionsList.png'
import AvailableSessionModalImage from '../images/availableSessionsModal.png'
import SessionInputImage from '../images/sessionInputDataLoader.png'
import GeneratePlotsButtonImage from '../images/GenPlotButtonDataLoader.png'
import UngroupedPlotsImage from '../images/ungroupedPlotsDataLoader.png'
import GroupedPlotsImage from '../images/groupedPlotsDataLoader.png'
import TemplateBuilderPageImage from '../images/TemplateBuilderPage.png'
import TemplateBuilderCreationImage from '../images/TemplateBuilderCreation.png'
import TemplateBuilderAvailableTemplatesImage from '../images/TemplateBuilderAvailableTemplates.png'
import TemplatesBuilderAddParamImage from '../images/TemplateBuilderAddParameters.png'
import TemplatesBuilderRenameTemplatesImage from '../images/TemplateBuilderRenameTemplates.png'
import MetricsSelectOperationImage from '../images/metrics_select_report_or_plot.png'
import MetricsReportingSelectMetricImage from '../images/metrics_reports_select_metric.png'
import MetricsReportingSelectReportDropDownImage from '../images/metrics_reports_select_report_dropdown.png'
import MetricReportingSelectReportModalImage from '../images/metrics_reports_select_report_modal.png'
import MetricReportingReportImage from '../images/metrics_reports_generatedreport.png'
import MetricPlottingSelectMetricImage from '../images/metrics_plotting_select_metric.png'
import MetricPlottingPlotImage from '../images/metrics_plotting_plot.png'
import DSLABInputModelsImage from '../images/dslab_input_models.png'
import DSLabInputSignalImage from '../images/dslab_input_signal.png'
import DSLabTimestampSliderImage from '../images/dslab_timestampslider.png'
import DSLabPlotsImage from '../images/dslab_noise_plots.png'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    color: '#444',
    marginBottom: '4px',
  },
  section: {
    marginTop: '10vh',
  },
};


class Help extends React.Component {

  render() {
    return (
      <div>
        <div>
          <h1 class="page-header"><center>Data Probe Tool Instructions</center></h1>
            <center><a href='#section1'>Section 1: Introduction</a> | <a href='#section2'>Section 2: Data Loader</a> | <a href='#section3'>Section 3: Templates</a> | <a href='#section4'>Section 4: Metrics</a> | <a href='#section5'>Section 5: DS Lab</a> </center>            
        </div>
        <div style={styles.section}> 
          <h1 style={styles.heading} id="section1">What Is Data Probe Tool?</h1>
              <p style={styles.message}>The Data Probe Tool is a data visualization web application that allows its users to visualize AHMS parameter and metric data.</p>
              <p style={styles.message}>The several pages of the Data Probe Tool can be navigated through the sidebar on the left of the screen.</p>
              <p style={styles.message}>The Data Loader page allows users to plot parameter data of a specified quality for a specific aircraft, flight session and phase.</p>
              <img src={DataLoaderPageImage} alt="Data Loader Page"></img>
              <p style={styles.message}>The Template Builder page allows users to create and manage parameter templates used in the data loader.</p>  
              <p style={styles.message}>The Metric page allows users to view reports and plots of specified metrics and determine the quality of the trained models.</p>
              <p style={styles.message}>The Data Science Lab page allows users to visualize noise function data for specifiedsentry model, signal and timestamps.</p>
              
        </div>
        <div style={styles.section}> 
          <h1 style={styles.heading} id="section2">How To Use Data Loader</h1>
              <p style={styles.message}>The Data Loader page can be used to plot parameter data for a user specified set of parameters, ATA, aircraft, session number and flight phase.</p>
              <p style={styles.message}>The data quality of the parameters being loaded can be selected in the "Data Bucket" section</p>
              <img src={DataBucketImage} alt="An Image of the Data Bucket Section on the Data Loader Page"></img>
              <p style={styles.message}>The desired parameters to be plotted can be selected one by one from the parameters list dropdown. Users may also type in the desired parameters to help select them from the list.</p>
              <p style={styles.message}>This list contains both regular and engineered parameters. To filter for only engineered parameters, users may simply type "eng" into the parameter search bar.</p>
              <img src={ParameterSelectionImage} alt="An Image of the Parameter Selection List"></img>
              <p style={styles.message}>Alternatively, the desired parameters can also be selected from a user created parameter template. These templates can be created and managed in the template builder and used in the data loader page to save the user from having to constantly select the same list of parameters.</p>
              <img src={TemplateSelectionImage} alt="An Image of the Template Selection List"></img>

              <p style={styles.message}>The session details such as the ATA for the loaded parameters, the desired aircraft's number and the flight phase can be selected in the 3 drop down lists</p>
              <p style={styles.message}>If plotting parameters with bronze quality data then users need not specify the ATA and flight phase.</p>
              <img src={SessionDetailsSelectionImage} alt="An Image of the Session Details Selection Lists"></img>
              <p style={styles.message}>Users can also directly input an S3 URL instead of manually selecting the session details and session</p>
              <img src={S3URLInputImage} alt="An Image of the S3 URL Input for Session Details"></img>
              <p> </p>
              <p style={styles.message}>All available sessions for the given session details can be searched over a sepcified date range by selecting a start and end date from the calendar menus and clicking "Find Sessions"</p>
              <img src={SessionSearchDateRangeImage} alt='An Image of the Session Search Date Range'></img>
              <p style={styles.message}>This will return a list of available sessions inbetween those dates that can be used to load the parameter data from</p>
              <p style={styles.message}>Users can select a desired available session from the newly generated drop down list of available sessions. The dates corresponding to each session can be seen in the session modal (click the icon to the right of the available sessions drop down)</p>
              <img src={AvailableSessionSelectionImage} alt="An Image of the Available Sessions Selection List"></img>
              <img src={AvailableSessionModalImage} alt="An Image of the Available Session Modal"></img>
              <p style={styles.message}>If no session can be found for the specified plane during the input date range then users will received a "No Sessions Found" message and will have to vary the date range or desired aircraft before searching again.</p>
              <p style={styles.message}>Users can also directly input the session number if they already know the wanted session</p>
              <img src={SessionInputImage} alt="An Image of the Session Number Input Field"></img>

              <p style={styles.message}>Once all this information has been input the user can press the "Generate Plots" button to generate the parameter plots</p>
              <img src={GeneratePlotsButtonImage} alt="An Image of the Generate Plots Button"></img>
              <p style={styles.message}>The generated plots will appear below, Note that this may take a few seconds. Users can select and de-select the group parameters checkbox at any time to group parameters with the same units of measurement and sampling rates into the same plots</p>
              <p style={styles.message}>Note that in grouped plots clicking on a parameter in the legend will isolate it in the plot</p>
              <img src={UngroupedPlotsImage} alt="An Image of Ungrouped Parameter Plots"></img>
              <p></p>
              <p></p>
              <img src={GroupedPlotsImage} alt="An Image of Grouped Parameter Plots"></img>
              <p style={styles.message}>Users may press the clear data button to clear all the input data and remove the plots. Essentially resetting the data loader</p>
        </div>
        <div style={styles.section}>
          <h1 style={styles.heading} id="section3">How To Use Template Builder</h1>
              <p style={styles.message}>The Template Builder allows for users to create new parameter templates and manage prexisting ones. A parameter template is a list of parameters which can be saved for convenience sake so that the user does not need to re-enter them one by one in the data loader each time they wish to generate plots</p>
              <img src={TemplateBuilderPageImage} alt="An Image of the Template Builder Page"></img>
              <p style={styles.message}>New templates can be created by inputting a name for the template and selecting all the desired parameters from the select parameters drop down list and then clicking the "Create Template" button.</p>
              <img src={TemplateBuilderCreationImage} alt="An Image of a new template being created in the Template Builder Page"></img>
              <p style={styles.message}>Existing templates can be viewed, deleted, renamed, and modified by selecting the template in the "Check Available Templates" drop down.</p>
              <p style={styles.message}>Upon selecting an existing template a list of its parameters will appear below.</p>
              <p style={styles.message}>Thetemplate can be permanently deleted by clicking the "Delete Template" button.</p>
              <p style={styles.message}>Any parameters may be removed from the template by clicking the "Remove Parameter" button corresponding to the parameter in the list.</p>
              <img src={TemplateBuilderAvailableTemplatesImage} alt="An Image of the previously created template being viewed in the Template Builder Page"></img>
              <p style={styles.message}>Parameters may be added to the template by clicking the "Add Parameter" button and selecting all the desired parameters to be added from the drop down menu.</p>
              <p style={styles.message}>Press the "Done" button to commit these changes and add the selected parameters to the template.</p>
              <img src={TemplatesBuilderAddParamImage} alt="An Image of the add parameter to template modal."></img>

              <p style={styles.message}>The template may be permanently renamed by clicking the "Rename Template" button and inputting the new desired template name.</p>
              <p style={styles.message}>Press the "Done" button to commit these chagnes and rename the selected template.</p>
              <img src={TemplatesBuilderRenameTemplatesImage} alt="An Image of rename template modal."></img>


        </div>
        <div style={styles.section}>
          <h1 style={styles.heading} id="section4">How To Use Metrics Page</h1>
              <p style={styles.message}>The Metrics Page contains two seperate visualizations: reports and plots</p>
              <p style={styles.message}>Metric reports are pre-generated HTML reports containing various metrics describing the performance of the model.</p>
              <p style={styles.message}>Metric plots are simple plots containing various metrics describing the preformance of the model.</p>
              <p style={styles.message}>The metrics described by either plot or report are specified by the user.</p>

              <p style={styles.message}>METRIC REPORTING: </p>
              <p style={styles.message}>Firstly, users must choose between visualizing a metric report or plot</p>
              <img src={MetricsSelectOperationImage} alt="An Image of metric report or plotting radio buttons"></img>
              <p style={styles.message}>Secondly, users must select a metric to visualize.</p>
              <img src={MetricsReportingSelectMetricImage} alt="An Image of select metric dropdown for metric reporting"></img>
              <p style={styles.message}>Thirdly, users must search for a metric report for the specified metrics using the date range search</p>
              <p style={styles.message}>Users must provide the start and end dates for search through the calendars and press the "Find Metric Reports" button.</p>
              <p style={styles.message}>Then they may select the desired metric report from either the drop down or the modal.</p>
              <img src={MetricsReportingSelectReportDropDownImage} alt="An Image of select metric report drop down for metric reporting"></img>
              <img src={MetricReportingSelectReportModalImage} alt="An Image of select metric modal for metric reporting"></img>
              <p style={styles.message}>Lastly, users must press the 'Generate Report' button.0</p>
              <img src={MetricReportingReportImage} alt="An Image of "></img>

              <p style={styles.message}>METRIC PLOTTING: </p>
              <p style={styles.message}>Firstly, users must choose between visualizing a metric report or plot.</p>
              <img src={MetricsSelectOperationImage} alt="An Image of metric report or plotting radio buttons"></img>
              <p style={styles.message}>Secondly, users must select a metric to visualize.</p>
              <img src={MetricPlottingSelectMetricImage} alt="An Image of select metric drop down for metric plotting"></img>
              <p style={styles.message}>Thirdly, users must press the 'Generate Plot' button.</p>
              <img src={MetricPlottingPlotImage} alt="An Image of metric plotting plot"></img>
        </div>

        <div style={styles.section}>
          <h1 style={styles.heading} id="section5">How To Use Data Science Lab Page</h1>
              <p style={styles.message}>The data science lab page allows you to visualize sentry model noise function data.</p>
              <p style={styles.message}>This tool is useful for investigation into the sentry model's performance and should be viewed alongside the inference data, which is not yet currently accesible through the data probe tool</p>
              <p style={styles.message}>Firstly, users must input the sentry_detector_ml and standardized_object S3 paths for the desired sentry model and press the "Load Model" button.</p>
              <img src={DSLABInputModelsImage} alt="An Image of input forms for sentry detector ml and standardized object"></img>
              <p style={styles.message}>Secondly, users must select the desired signal to visualize from the drop down menu.</p>
              <img src={DSLabInputSignalImage} alt="An Image of drop down for sentry model signal"></img>
              <p style={styles.message}>Thirdly, users must select the desired range of timestamps to be visualized. Each timestamp is visualized individually into its own plot.</p>
              <p style={styles.message}>Users may visualize a single timestamp by moving both cursors onto the same timestamp in the range.</p>
              <img src={DSLabTimestampSliderImage} alt="An Image of timestamp slider for selecting timestamp range"></img>
              <p style={styles.message}>Lastly, users must press the "visualize model" button to genrate the plots. Each plot represents a timestamp as specified in the plot title.</p>
              <p style={styles.message}>The threshold value for each plot is also included in the title and the plot visually as a red horizontal line.</p>
              <img src={DSLabPlotsImage} alt="An Image of the generated sentry timestamp plot with the threshold line"></img>
        </div>
      </div>
    );
  }
}

export default Help;
