import React, { Component } from 'react';
import '../styles/MultiStepForm.css';
import { Aircrafts, ATA, Phases } from "../data/StaticFlightInfo.js";
import { default as ReactSelect } from "react-select";
import { Col, Row, Button } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import { FaTable } from 'react-icons/fa';
import ParamsPlotsModel from './ParamsPlotsModel'
import SessionModal from './SessionModal';
import { components } from "react-select";
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import getApiUrl from './config.js';
const apiUrl = getApiUrl();

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function CustomButton({ children, onClick }) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Button variant="outline-dark" className="custom-button" onClick={handleClick}>
      {children}
    </Button>
  );
}


export default class MultiStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      paramsSearchKeyword: "",
      plotsX: [],
      plotsY: [],
      paramGroups: {},
      paramMap: {},
      parameterOptions: [],
      availableTemplates: [],
      requestedSessions: false,
      loadedParams: [],
      sessionID: "",
      s3URL: "",
      requestedPlots: false,
      plotsLoading: false,
      kindOfExtraction: "id",
      kindOfParamSelection: "list",
      paramsSelected: [],
      templatesSelected: null,
      selectedStartDate: null,
      selectedEndDate: null,
      dataBucket: "silver",
      selectedPhase: "",
      selectedAircraft: "",
      selectedATA: "",
      kindOfSessionSelection: "search",
      searchedSessions: [],
      showModal: false,
      availableSessionsMetaData: [],
      grouping: true,
    };
    this.handleParametersSelection = this.handleParametersSelection.bind(this);
    this.handleExtractionChange = this.handleExtractionChange.bind(this);
    this.handleGroupingSelection = this.handleGroupingSelection.bind(this);
    this.handleParamSelectionMode = this.handleParamSelectionMode.bind(this);
    this.handleDataBucket = this.handleDataBucket.bind(this);
    this.generate_plots = this.generate_plots.bind(this);
  }

  componentDidMount() {
    // Fetch the parameter options from the backend JSON file
    axios.get(apiUrl + '/api/parameters')
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          this.setState({
            parameterOptions: response.data.parameters,
            availableTemplates: response.data.templates
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };


  handleSessionModal = (selectedValue, selectedLabel) => {
    this.setState({
      sessionID: {
        value: selectedValue,
        label: selectedLabel,
      },
      showModal: false, // Close the modal after selection
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }));
  };


  handleCallback = (childData) => {
    this.setState({ plotsLoading: childData })
  }

  handleDateChange = (dateType) => (event) => {
    const value = event.target.value;
    this.setState({
      [dateType]: value,
    });
  };

  handleParamsListInputChange = (paramsSearchKeyword, e) => {
    if (e.action === 'input-change') {
      this.setState({ paramsSearchKeyword });
    }
  }

  // TODO
  getValidationState() {
    const length = this.state.value.length;
    if (length > 10) return 'success';
    if (length > 5) return 'warning';
    if (length > 0) return 'error';
    return null;
  }

  handleParametersSelection = (selected) => {
    this.setState({
      paramsSelected: selected
    });
  };



  handleParamsTemplatesSelection = (selected) => {
    this.setState({
      templatesSelected: selected
    });
  };

  handleChange = (selectedOption, { name }) => {
    this.setState({ [name]: selectedOption });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleExtractionChange = (e) => {
    this.setState({
      kindOfExtraction: e.target.value
    });
  };

  handleGroupingSelection = () => {
    this.setState({
      grouping: !this.state.grouping
    });
  };

  handleSessionSelectionChange = (e) => {
    this.setState({
      kindOfSessionSelection: e.target.value
    });
  };

  handleDataBucket = (e) => {
    this.setState({
      dataBucket: e.target.value
    });
  };


  handleParamSelectionMode = (e) => {
    this.setState({
      kindOfParamSelection: e.target.value
    });
  };

  clear_data = () => {
    this.setState({
      value: '',
      paramsSearchKeyword: "",
      plotsX: [],
      plotsY: [],
      paramGroups: {},
      paramMap: {},
      requestedSessions: false,
      loadedParams: [],
      sessionID: "",
      s3URL: "",
      requestedPlots: false,
      plotsLoading: false,
      kindOfExtraction: "id",
      kindOfParamSelection: "list",
      paramsSelected: [],
      templatesSelected: null,
      selectedStartDate: null,
      selectedEndDate: null,
      dataBucket: "silver",
      selectedPhase: "",
      selectedAircraft: "",
      selectedATA: "",
      kindOfSessionSelection: "input",
      searchedSessions: [],
      showModal: false,
      availableSessionsMetaData: [],
      grouping: true,
    });
  }

// API call to the backend for retrieving sessions' data 
  find_sessions = () => {
    if (this.state.selectedAircraft !== "") {
      if (this.state.selectedStartDate === null && this.state.selectedEndDate === null) {
        alert("Atleast one date is required!")
      }

      else if (this.state.selectedStartDate > this.state.selectedEndDate) {
        alert("Start date cannot be greater than end date!")
      }
      else {
        this.setState({
          requestedSessions: true,
        });
        const dataToSend = {
          selectedAircraft: this.state.selectedAircraft,
          selectedPhase: this.state.selectedPhase,
          selectedATA: this.state.selectedATA,
          selectedStartDate: this.state.selectedStartDate,
          selectedEndDate: this.state.selectedEndDate
        };

        axios.get(apiUrl + '/api/find_sessions', { params: dataToSend })
          .then(response => {
            if (response.data.status === "success") {

              // Extract values from each dictionary and set the state
              const extractedValues = response.data.availableSessions.map(session => ({
                value: session.value,
                label: session.label,
                date: session.date,
              }));

              // Handle the received data
              this.setState({
                availableSessionsMetaData: extractedValues,
                searchedSessions: extractedValues,
                requestedSessions: false
              });
              
              // if no sessions could be found, alert the user
              if(extractedValues.length === 0){
                alert("No Sessions Found")
              }
            }
            else {
              this.setState({
                requestedSessions: false
              });
              alert("Unable to find any session. Check provided details.")
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    }
    else {
      alert("Aircraft Number and atleast one Date are necessary for searching sessions!")
    }
  };

 // API call to the backend for retrieving plots data 
  generate_plots = () => {
    // Resetting the states so that the previously loaded plots are removed
    this.setState({
      loadedParams: [],
      plotsX: [],
      plotsY: [],
      paramGroups: {},
      paramMap: {},
    });

    let values = [];
    let templates = []

    if (this.state.kindOfParamSelection === "list") {
      values = this.state.paramsSelected.map(item => item.value)
    }
    else {
      templates = this.state.templatesSelected.map(item => item.value)
    }
    if (((this.state.s3URL === "") && (this.state.selectedAircraft === "" && this.state.sessionID === "" && this.state.selectedATA === "")) || (templates.length === 0 && values.length === 0)) {
      alert("Please provide all details to load data!")
    }
    else {
      this.setState({
        requestedPlots: true,
        plotsLoading: true
      });

      const dataToSend = {
        selectedAircraft: this.state.selectedAircraft,
        sessionID: this.state.sessionID,
        selectedParams: values,
        selectedPhase: this.state.selectedPhase,
        s3URL: this.state.s3URL,
        extractionMode: this.state.kindOfExtraction,
        paramsSelectionMode: this.state.kindOfParamSelection,
        selectedATA: this.state.selectedATA,
        selectedTemplates: templates,
        dataBucket: this.state.dataBucket
      };

      axios.get(apiUrl + '/api/data', { params: dataToSend }) 
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              loadedParams: response.data.loadedParams,
              plotsX: response.data.data_x,
              plotsY: response.data.data_y,
              paramGroups: response.data.groups,
              paramMap: response.data.mapping,
            });
            this.setState({
              plotsLoading: false
            })
          }
          else {
            this.setState({
              plotsLoading: false
            })
            alert("Unable to find the session. Check provided details.")
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };



  render() {
    return (
      <div>
        <h1 class="page-header"><center>Data Loader</center></h1>
        <br></br>
        <p>Note: Engineered Parameters may not generate properly if using silver and gold quality parameters</p>
        <div className="form-wrapper">
          <Row className='mt-1'>
            <Col sm={5}>
              <Form.Label class="field-header">Data Bucket</Form.Label>
            </Col>
            <Col sm={2}>
              <Form.Check
                value="bronze"
                type="radio"
                className="field-body"
                aria-label="radio 7"
                label="Bronze"
                onChange={this.handleDataBucket}
                checked={this.state.dataBucket === "bronze"}
              />
            </Col>
            <Col sm={2}>
              <Form.Check
                value="silver"
                type="radio"
                className="field-body"
                aria-label="radio 8"
                label="Silver"
                onChange={this.handleDataBucket}
                checked={this.state.dataBucket === "silver"}
              />
            </Col>
            <Col sm={2}>
              <Form.Check
                value="gold"
                type="radio"
                className="field-body"
                aria-label="radio 9"
                label="Gold"
                onChange={this.handleDataBucket}
                checked={this.state.dataBucket === "gold"}
              />
            </Col>
          </Row>

        </div>
        <div className="form-wrapper">
          <Row className='mt-1'>
            <Col sm={5}>
              <Form.Label class="field-header">Parameters Selection Mode</Form.Label>
            </Col>

            <Col sm={4}>
              {this.state.kindOfParamSelection === "list" && (
                <Form.Label class="field-header">Select from Parameters List</Form.Label>
              )}

              {this.state.kindOfParamSelection === "template" && (

                <Form.Label class="field-header">Select from Templates</Form.Label>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={2}>
              <Form.Check
                value="list"
                type="radio"
                className="field-body"
                aria-label="radio 3"
                label="Parameters List"
                onChange={this.handleParamSelectionMode}
                checked={this.state.kindOfParamSelection === "list"}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                value="template"
                type="radio"
                className="field-body"
                aria-label="radio 4"
                label="Parameters Template"
                onChange={this.handleParamSelectionMode}
                checked={this.state.kindOfParamSelection === "template"}
              />
            </Col>


            <Col sm={7}>
              {this.state.kindOfParamSelection === "list" && (
                <span style={{ width: "100%" }}
                  class="d-inline-block"
                  data-toggle="popover"
                  data-trigger="focus"
                >
                  <ReactSelect
                    isDisabled={this.state.kindOfParamSelection === "template"}
                    noOptionsMessage={() => "No parameters available!"}

                    options={this.state.parameterOptions.map((parameter) => ({
                      label: parameter.parameter_name,
                      value: parameter.parameter_id,
                    }))}
                    isMulti
                    className="field-body"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    components={{
                      Option
                    }}
                    onChange={this.handleParametersSelection}
                    allowSelectAll={true}
                    value={this.state.paramsSelected}
                    onInputChange={this.handleParamsListInputChange}
                    inputValue={this.state.paramsSearchKeyword}
                  />
                </span>
              )}


              {this.state.kindOfParamSelection === "template" && (
                <span style={{ width: "100%" }}
                  class="d-inline-block"
                  data-toggle="popover"
                  data-trigger="focus"
                >
                  <ReactSelect
                    isDisabled={this.state.kindOfParamSelection === "list"}
                    noOptionsMessage={() => "No templates available!"}
                    options={this.state.availableTemplates.map((template) => ({
                      label: template,
                      value: template,
                    }))}
                    isMulti
                    className="field-body"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    components={{
                      Option
                    }}
                    onChange={this.handleParamsTemplatesSelection}
                    allowSelectAll={true}
                    value={this.state.templatesSelected}
                  />
                </span>

              )}
            </Col>
          </Row>
        </div>


        <div className="form-wrapper">
          <Row className='mt-1'>

            <Col sm={5}>
              <Form.Label class="field-header">Extraction Mode</Form.Label>
            </Col>

            <Col sm={7}>
              {this.state.kindOfExtraction === "id" && (
                <Row>
                  <Col sm={4}>
                    <Form.Label class="field-header">ATA</Form.Label>
                  </Col>

                  <Col sm={4}>
                    <Form.Label class="field-header">Aircraft</Form.Label>
                  </Col>

                  <Col sm={3}>
                    <Form.Label class="field-header">Phase</Form.Label>
                  </Col>
                </Row>

              )}

              {this.state.kindOfExtraction === "url" && (
                <div>
                  <Col sm={7}>
                    <Form.Label class="field-header">URL</Form.Label>
                  </Col>
                </div>)}
            </Col>
          </Row>

          <Row className='mt-1'>
            <Col sm={2}>
              <Form.Check
                value="id"
                type="radio"
                aria-label="radio 2"
                label="Session Details"
                className="field-body"
                onChange={this.handleExtractionChange}
                checked={this.state.kindOfExtraction === "id"}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                value="url"
                type="radio"
                aria-label="radio 1"
                label="S3 URL"
                className="field-body"
                onChange={this.handleExtractionChange}
                checked={this.state.kindOfExtraction === "url"}
              />
            </Col>

            <Col sm={7}>
              {this.state.kindOfExtraction === "id" && (
                <Row>
                  <Col sm={3}>
                    <span style={{ width: "100%" }}
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                    >
                      <ReactSelect
                        isDisabled={this.state.dataBucket === "bronze"}
                        noOptionsMessage={() => "No ATAs available!"}
                        options={ATA}
                        className="field-body"
                        closeMenuOnSelect={true}
                        hideSelectedOptions={true}
                        components={{
                          Option
                        }}
                        onChange={(selectedOption) => this.handleChange(selectedOption, { name: "selectedATA" })}
                        allowSelectAll={false}
                        value={this.state.selectedATA}
                      />
                    </span>
                  </Col>

                  <Col sm={1}>
                  </Col>

                  <Col sm={3}>
                    <span style={{ width: "100%" }}
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                    >
                      <ReactSelect
                        noOptionsMessage={() => "No aircrafts available!"}
                        options={Aircrafts}
                        className="field-body"
                        closeMenuOnSelect={true}
                        hideSelectedOptions={true}
                        id="selectedAircraft"
                        components={{
                          Option
                        }}
                        onChange={(selectedOption) =>
                          this.handleChange(selectedOption, { name: "selectedAircraft" })
                        }
                        allowSelectAll={false}
                        value={this.state.selectedAircraft}
                      />
                    </span>
                  </Col>

                  <Col sm={1}>
                  </Col>

                  <Col sm={3}>
                    <span style={{ width: "100%" }}
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                    >
                      <ReactSelect
                        isDisabled={this.state.dataBucket === "bronze"}
                        noOptionsMessage={() => "No phases available!"}
                        options={Phases}
                        className="field-body"
                        closeMenuOnSelect={true}
                        hideSelectedOptions={true}
                        components={{
                          Option
                        }}
                        onChange={(selectedOption) =>
                          this.handleChange(selectedOption, { name: "selectedPhase" })
                        }
                        allowSelectAll={false}
                        value={this.state.selectedPhase}
                      />
                    </span>

                  </Col>
                </Row>
              )}

              {this.state.kindOfExtraction === "url" && (
                <Col sm={12}>
                  <Form.Control className="field-body2" type="text" placeholder="s3a://bba-operationalzone-predictive-maintenance-project/data/01_bronze/new_sessions_ahms_parameters/serial_number=102/session_number=0000134-1361/" name="s3URL" value={this.state.s3URL} onChange={this.handleInputChange} />
                </Col>
              )}

            </Col>
          </Row>

          <Row className='mt-5'>

            <Col sm={5}>
              {this.state.kindOfExtraction === "id" && (
                <Form.Label class="field-header">Session Selection</Form.Label>
              )}
            </Col>

            <Col sm={7}>
              {this.state.kindOfExtraction === "id" && this.state.kindOfSessionSelection === "search" && (
                <Row>
                  <Col sm={4}>
                    <Form.Label class="field-header">Date Range</Form.Label>
                  </Col>

                  <Col sm={4}>
                  </Col>

                  {this.state.requestedSessions === false && this.state.searchedSessions.length > 0 && (
                    <Col sm={4}>
                      <Form.Label class="field-header">Available Sessions</Form.Label>
                    </Col>)}
                </Row>)}

              {this.state.kindOfExtraction === "id" && this.state.kindOfSessionSelection === "input" && (
                <Row>
                  <Col sm={4}>
                    <Form.Label class="field-header">Flight Session</Form.Label>
                  </Col>
                </Row>)}
            </Col>
          </Row>

          {this.state.kindOfExtraction === "id" && (
            <Row className='mt-1'>
              <Col sm={2}>
                <Form.Check
                  value="search"
                  type="radio"
                  aria-label="radio 5"
                  label="Search"
                  className="field-body"
                  onChange={this.handleSessionSelectionChange}
                  checked={this.state.kindOfSessionSelection === "search"}
                />
              </Col>
              <Col sm={3}>
                <Form.Check
                  value="input"
                  type="radio"
                  aria-label="radio 6"
                  label="Input"
                  className="field-body"
                  onChange={this.handleSessionSelectionChange}
                  checked={this.state.kindOfSessionSelection === "input"}
                />
              </Col>

              <Col sm={7}>
                {this.state.kindOfExtraction === "id" && this.state.kindOfSessionSelection === "search" && (
                  <div>
                    <Row>
                      <Col sm={3}>
                        <Form.Control
                          className="field-body"
                          type="date"
                          value={this.state.selectedStartDate}
                          onChange={this.handleDateChange('selectedStartDate')}
                        />
                      </Col>

                      <Col sm={3}>
                        <Form.Control
                          className="field-body"
                          type="date"
                          value={this.state.selectedEndDate}
                          onChange={this.handleDateChange('selectedEndDate')}
                        />
                      </Col>

                      <Col sm={2}>
                      </Col>

                      <Col sm={3}>
                        {this.state.requestedSessions === true && (
                          <Spinner style={{
                            marginTop: 5,
                            marginLeft: 65
                          }}
                            as="span"
                            animation="grow"
                            size="lg"
                            variant="dark"
                            role="status"
                            aria-hidden="true"
                          />)}

                        {(this.state.requestedSessions === false && this.state.searchedSessions.length > 0) ?

                          <span style={{ width: "100%" }}
                            class="d-inline-block"
                            data-toggle="popover"
                            data-trigger="focus"
                          >
                            <ReactSelect
                              noOptionsMessage={() => "No sessions available!"}
                              options={this.state.searchedSessions}
                              className="field-body"
                              closeMenuOnSelect={true}
                              hideSelectedOptions={true}
                              components={{
                                Option
                              }}
                              onChange={(selectedOption) =>
                                this.handleChange(selectedOption, { name: "sessionID" })
                              }
                              allowSelectAll={false}
                              value={this.state.sessionID}
                            />
                          </span>

                          : null}
                      </Col>
                      {(this.state.requestedSessions === false && this.state.searchedSessions.length > 0) ?
                        <Col sm={1}>
                          <div className="table-icon" onClick={this.toggleModal}>
                            <FaTable />
                          </div>

                          <SessionModal
                            showModal={this.state.showModal}
                            availableSessions={this.state.availableSessionsMetaData}
                            onHide={this.toggleModal}
                            onSelect={this.handleSessionModal}
                            selectedSession={this.state.sessionID}
                          />
                        </Col> : null}
                    </Row>
                    <Row>
                      <Col md={4} style={{ marginTop: 5 }}>
                        <CustomButton onClick={this.find_sessions}>Find Sessions</CustomButton>
                      </Col>
                    </Row>

                  </div>)}

                {this.state.kindOfExtraction === "id" && this.state.kindOfSessionSelection === "input" && (
                  <Row>
                    <Col sm={4}>
                      <Form.Control type="text" className="field-body" name="sessionID" value={this.state.sessionID} onChange={this.handleInputChange}
                        placeholder="0000134-1361" />
                    </Col>
                  </Row>)}
              </Col>
            </Row>)}
        </div >

        <Row>
          <Col sm={2}>
            <CustomButton onClick={this.generate_plots}>Generate Plots</CustomButton>
          </Col>
          <Col sm={2}>
            <CustomButton onClick={this.clear_data}>Clear Data</CustomButton>
          </Col>
          <Col sm={3}>
            <Form.Check
              className="field-header-grouping"
              type="checkbox"
              label="Parameter Grouping"
              id="groupingCheckbox"
              value="true"
              checked={this.state.grouping}
              onClick={this.handleGroupingSelection}
            />
          </Col>
        </Row>



        <div >
          {(this.state.requestedPlots) ?
            <div className='plots-wrapper'> {(this.state.plotsLoading) ?
              <Spinner style={{
                marginTop: 200,
                marginLeft: 800,
                marginBottom: 200,
              }}
                as="span"
                animation="grow"
                size="lg"
                variant="dark"
                role="status"
                aria-hidden="true"
              /> : null}

              {(this.state.dataBucket !== "") ?
                <ParamsPlotsModel plotsData={[this.state.plotsX, this.state.plotsY]} loadedParams={this.state.loadedParams} grouping={this.state.grouping} groups={this.state.paramGroups} mapping={this.state.paramMap} />
                : null}
            </div>
            : null
          }
        </div>


      </div >
    );
  }
}

