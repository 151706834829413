import React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    color: '#888',
  },
};

class WorkInProgress extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Work in Progress</h1>
        <p style={styles.message}>This Settings page is currently under development. Stay tuned!</p>
      </div>
    );
  }
}

export default WorkInProgress;
