import React from 'react';
import Plot from 'react-plotly.js';
import Spinner from 'react-bootstrap/Spinner';

class ParamsPlotsModel extends React.Component {
  computingPlots
  constructor(props) {
    super(props);
    this.state = {
      computingPlots: true
    };
  }


  renderPlots = () => {
    var numberOfPlots = this.props.plotsData[0].length;
    const loadedParams = this.props.loadedParams;
    const plots = [];
    
    if(this.props.grouping){
      for (let i = 0; i < numberOfPlots; i++) {
        var data = [];
        if(this.props.plotsData[0][i] != null){
          for (let j = 0; j < this.props.plotsData[0][i].length; j++){
            data[j] =
              {
                x: this.props.plotsData[0][i][j],
                y: this.props.plotsData[1][i][j],
                mode: 'lines',
                name: this.props.mapping[this.props.groups[i+1][j+1]]
              }
          }
          const layout = {
            width: 1400,
            height: 400,
            automargin: true,
            yaxis: {
              title: {
                text: this.props.groups[i+1][0]
              }
            },
            xaxis: {
              title: {
                text: "Timestamp"
              }
            }
          };
          if (this.props.plotsData[0][i].length > 1){
            var plot_title = "Group " + (i+1);
          }
          else{
            var plot_title = this.props.mapping[this.props.groups[i+1][1]];
            if (plot_title == undefined){
              plot_title = this.props.groups[i+1][1];
            }
          }

          plots.push(
            <div key={i}>
              <br></br>
              <h3><center>{plot_title}</center></h3>
              <Plot style={{
                    marginLeft: 40,
                  }} data={data} layout={layout} />
              
            </div>
          );
        }
      }
    }
    else{
      var index = 0;
      for (let i = 0; i < numberOfPlots; i++) {
        if(this.props.plotsData[0][i] != null){
          for (let j = 0; j < this.props.plotsData[0][i].length; j++){
            const data = [
              {
                x: this.props.plotsData[0][i][j],
                y: this.props.plotsData[1][i][j],
                mode: 'lines',
                name: this.props.mapping[this.props.groups[i+1][j+1]]
              },
            ];
            const layout = {
              width: 1400,
              height: 400,
              automargin: true,
              yaxis: {
                title: {
                  text: this.props.groups[i+1][0]
                }
              },
              xaxis: {
                title: {
                  text: "Timestamp"
                }
              }
            };
            var plot_title = this.props.mapping[this.props.groups[i+1][j+1]];
            if (plot_title == undefined){
              plot_title = this.props.groups[i+1][j+1];
            }

            plots.push(
              <div key={index}>
                <br></br>
                <h3><center>{plot_title}</center></h3>
                <Plot style={{
                      marginLeft: 40,
                    }} data={data} layout={layout} />
                
              </div>
            );
            index += 1;
          }
        }
      }
    }

    return plots;
  };

  render() {
    return (
      <div>
        {this.renderPlots()}
      </div>
    );
  }
}

export default ParamsPlotsModel;