import React, { Component } from 'react';
import '../styles/MultiStepForm.css'; // Import the custom CSS file for styling
import { Modal } from 'react-bootstrap';
import { Col, Row, Form } from "react-bootstrap";
import { CustomButton } from "./TemplatesBuilder";

class RenameTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name:"",
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    changeName = () => {
        // call the parent component (templatesbuilder) function which will call the backend to change the template name
        this.props.parentRenameTemplate(this.state.name);
    }

    render(){
        const { showModal, onHide } = this.props;
        return(
            <Modal show={showModal} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Rename Template</Modal.Title>
            </Modal.Header>
            <Modal.Body className="field-body">
                <div>
                    <Row className='mt-1'>
                    <Col sm={12}>
                        <Form.Label class="field-header">Rename Template</Form.Label>
                    </Col>
                    </Row>
                    <Row className='mt-1'>
                    <Col sm={12}>
                        <Form.Control className="field-body" type="text" placeholder="" name="name" value={this.state.name} onChange={this.handleInputChange} />
                    </Col>
                    </Row>
                    <Row className='mt-1'>
                    <Col sm={12}>
                        <CustomButton onClick={this.changeName}>Done</CustomButton>    
                    </Col>
                    </Row>
                    
                </div>
            </Modal.Body>
        </Modal>
        );
    }
}

export default RenameTemplateModal;