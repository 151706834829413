import React, { Component } from 'react';
import { CustomButton } from "./TemplatesBuilder";

const styles = {
    tableContainer: {
      maxHeight: '200px',
      overflowY: 'auto',
      border: '1px solid #d19000'
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      backgroundColor: 'white'
    },
    headerCell: {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      background: '#f9f9f9',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    dataCell: {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      textAlign: 'left',
    },
};

export class DataTable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedTemplateParameterID: "",
        selectedTemplateParameterIndex:"",
      }
    }
  
    selectRow(rowData, rowIndex){
      rowIndex = parseInt(rowIndex);
      // if there is a previously selected row, set its background color to white
      // added the previous parameter is 0 as for somereason that would cause the "this.state.selectedTemplateParameterIndex !='' " condition to be set to false...
      if(this.state.selectedTemplateParameterIndex == 0 || (this.state.selectedTemplateParameterID != '' && this.state.selectedTemplateParameterIndex !='')){
        // get the table from its id and a list of the rows from the tagname tr and then get the row at index +1 (because first row is just headers)
        document.getElementById('ParamTable').getElementsByTagName("tr")[this.state.selectedTemplateParameterIndex+1].style.backgroundColor='white';
      }
      // update the stored selected param id and index to be that of the newly selected row and update the color accordingly
      // THESE VALUES WILL NOT BE USED FOR NOW OTHER THAN FOR CHANGING SELECTED ROW COLOUR
      this.setState({
        selectedTemplateParameterID: rowData.parameter_id, 
        selectedTemplateParameterIndex: rowIndex
      });
      document.getElementById('ParamTable').getElementsByTagName("tr")[rowIndex+1].style.backgroundColor='grey';
    }
  
    table_remove_parameter(paramName){
      // pass the selected parameters ID to the handle delete parameter function to make a call to the api and remove the parameter from the template
      this.props.handle_remove_parameter(paramName)
    }
    
    render() {
      const { data } = this.props;
      
      return (
        <div style={styles.tableContainer}>
          <table style={styles.table} id="ParamTable">
            <thead>
              <tr>
                <th style={styles.headerCell}>S.No</th>
                <th style={styles.headerCell}>Parameter ID</th>
                <th style={styles.headerCell}>Parameter Label</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} onClick={this.selectRow.bind(this, item, index)}>
                  <td style={styles.dataCell}>{index}</td>
                  <td style={styles.dataCell}>{item.parameter_id}</td>
                  <td style={styles.dataCell}>{item.parameter_name}</td>
                  <td> <CustomButton onClick={this.table_remove_parameter.bind(this, item.parameter_name)}>Remove Parameter</CustomButton> </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  