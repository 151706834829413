import React from 'react';
import Plot from 'react-plotly.js';

class MetricPlotsModal extends React.Component {
    computingPlots
    constructor(props) {
        super(props);
        this.state = {
        computingPlots: true
        };
    }

    renderPlots = () => {
        var selected_metric = this.props.selectedMetric['label'];
        var data_x = this.props.plotsData[0];
        var data_y = this.props.plotsData[1];

        var trace = [{
            x: data_x,
            y: data_y,
            type: 'bar',
        }];
        const layout = {
            yaxis: {
              title: {
                text: "Overall Value"
              }
            },
            xaxis: {
              title: {
                text: "Metric"
              }
            },
            title: ('Metrics Plot: '+ selected_metric),
            showlegend: false
        };

        return(<div>
        <Plot style={{
            marginLeft: 40,
            }} data={trace} layout={layout} />
        </div>
        );
    }

    render() {
        return (
          <div>
            {this.renderPlots()}
          </div>
        );
    }
}


export default MetricPlotsModal;