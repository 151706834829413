import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Sidebar from "./sidebar";

const Layout = (props) => {
  const [showSidebar, setSidebar] = useState(false);

  return (
    <>
      <Container fluid className="mb-1">
        <Row>
          <Sidebar showSidebar={showSidebar} />

          <main className="custom-main">
            {props.children}
          </main>
        </Row>
        <footer className="footer">
          <div className="footer-banner">
            <p className="footer-text">Private and Confidential © Bombardier Inc or its Subsidiaries. All Rights Reserved.</p>
          </div>
        </footer>
      </Container>

      {/* CSS Style */}
      <style jsx>{`
        .container {
          max-width: 100vw;
          max-height: 100vh;
        }

        .custom-main {
          width: 100%;
          max-width: 75vw; /* Adjust the maximum width as per your needs */
          margin: 0 auto;
          padding: 0 5px; /* Adjust the horizontal padding as needed */
          display: block;
          align-items: center;
          justify-content: center;
          overflow-y: auto; /* Add vertical scrolling */
          overflow-x: hidden; /* Hide horizontal scrolling */
          max-height: calc(100vh - 50px); /* Adjust the maximum height as needed */
        }

        .footer {
          background-color: #333;
          color: white;
          padding: 2px;
          text-align: center;
        }

        .footer-banner {
          display: block;
          align-items: center;
          justify-content: center;
        }

        .footer-text {
          margin: 0;
        }
      `}</style>
    </>
  );
};

export default Layout;
