import { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.scss';
import DataLoader from './views/DataLoader';
import TemplatesBuilder from './views/TemplatesBuilder';
import Metrics from './views/Metrics';
import Login from './views/Login';
import Settings from "./views/Settings";
import ProtectedRoute from "./components/ProtectedRoute";
import Help from  './views/Help';
import DSLab from './views/DSLab';
import Puzzle from './components/Puzzle'; // Import your component
 

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();


  const handleLogin = () =>
    {
      setUser({
        id: "1",
        name: "admin",
      });
      navigate("/data-loader");
    }

  return (
    <>
      <Routes>
      <Route path="/news" element={<Puzzle />} />

        <Route path="/" element={<Login handleLogin={handleLogin} />} />
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/data-loader" element={<DataLoader/>} />
          <Route path="/templates-builder" element={<TemplatesBuilder />} />
          <Route path="/metrics" element={<Metrics />} />
          <Route path="/ds-lab" element={<DSLab />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<Help />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
