import React, { Component } from 'react';
import '../styles/MultiStepForm.css';
import { default as ReactSelect } from "react-select";
import { Col, Row, Button } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import { components } from "react-select";
import axios from 'axios';
import getApiUrl from './config.js';
import { DataTable } from './TemplateParameterTable.js';
import AddParameterModal from './AddParamModal';
import RenameTemplateModal from './RenameTemplateModal';
const apiUrl = getApiUrl();

// TODO: add method to force the refreshing of selected template table (for when parameters are added/deleted from template)

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export function CustomButton({ children, onClick }) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button variant="outline-dark" className="custom-button" onClick={handleClick}>
      {children}
    </Button>
  );
}


export default class TemplatesBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      paramsSearchKeyword: "",
      paramsSelected: [],
      parameterOptions: [],
      selectedTemplate: "",
      availableTemplates: [],
      data: [],
      showAddParamModal: false,
      showRenameTemplateModal: false,
    };
    
  }

  componentDidMount() {
    // Fetch the parameter options from the backend JSON file
    axios.get(apiUrl + '/api/parameters')
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          this.setState({
            parameterOptions: response.data.parameters,
            availableTemplates: response.data.templates
          });
        }
      })
      .catch(error => {
         // Handle errors during this request
        console.error('Error:', error);
      });
  };

  handleParamsListInputChange = (paramsSearchKeyword, e) => {
    if (e.action === 'input-change') {
      this.setState({ paramsSearchKeyword });
    }
  }

  deleteTemplate = () => {
    const dataToSend = {
      name: this.state.selectedTemplate,
    };

    axios.get(apiUrl + '/api/delete-template', { params: dataToSend })
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          alert("Deleted template successfully");
          this.refreshData();
        }
        else {
          alert("Template with this name doesn't exist!")
        }
      })
      .catch(error => {
        // Handle errors during this request
        console.error('Error:', error);
      });
  }

  refreshData = () => {
    axios.get(apiUrl + '/api/parameters')
      .then(response => {
        // this.build_traces(response.data.x, response.data.y)
        if (response.data.status === "success") {
          // Handle the received data
          this.setState({
            parameterOptions: response.data.parameters,
            availableTemplates: response.data.templates,
            selectedTemplate: "",
          });
        }
      })
      .catch(error => {
         // Handle errors during this request
        console.error('Error:', error);
      });
  }

  clearData = () => {
    this.setState({
      name: '',
      paramsSelected: [],
      parameterOptions: [],
      selectedTemplate: "",
      availableTemplates: [],
      data: [],
      showAddParamModal: false,
      showRenameTemplateModal: false,
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleParameterChange = (e) => {
    this.setState({ selectedParameter: e.target.value });
  };

  handleParametersSelection = (selected) => {
    this.setState({
      paramsSelected: selected
    });
  };

  handleChange = (selectedOption, { name }) => {
    this.setState({ [name]: selectedOption });
    const dataToSend = {
      name: selectedOption,
    };

    axios.get(apiUrl + '/api/read-template', { params: dataToSend })
      .then(response => {
        if (response.data.status === "success") {
          this.setState({
            data: response.data.parameters
          });
        }
        else {
          alert("No parameters available")
        }
      })
      .catch(error => {
       // Handle errors during this request
        console.error('Error:', error);
      });
  };


  createTemplate = () => {
    const values = this.state.paramsSelected.map(item => item.label)
    const dataToSend = {
      name: this.state.name,
      selectedParams: values,
    };

    axios.get(apiUrl + '/api/create-template', { params: dataToSend })
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          alert("Created template successfully");
          this.refreshData();
        }
        else {
          alert("Template with this name already exists! Use some other name.")
        }
      })
      .catch(error => {
        // Handle errors during this request
        console.error('Error:', error);
      });
  };

  handleRemoveParameter = (selectedParam) => {
    // make a call to the backend to a method which overwrites the yml file to remove the parameter from the template
    const dataToSend = {
      selectedTemplate: this.state.selectedTemplate,
      selectedParameter: selectedParam,
    };
    axios.get(apiUrl + '/api/remove-parameter', { params: dataToSend })
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          alert("Removed Parameter Successfully")
          // update the selectedTemplate with itself to re-render the table
          this.handleChange(this.state.selectedTemplate, { name: "selectedTemplate" });
        }
        else {
          alert("Template OR Parameter with this name doesn't exist!")
        }
      })
      .catch(error => {
        // Handle errors during this request
        console.error('Error:', error);
      });
  }

  // Handle when the user has selected a parameter from the Add Param Modal
  handleModalParameterSelection = (selectedParams) => {
    const values = selectedParams.map(item => item.label)
    // Hide the modal "POP UP" window
    this.setState({
      showAddParamModal: false
    });
    // Add the selected parameter to the currently selected template (we can be sure there is a currently selected template because you cant press add param button and access modal if not)
    // (This is done in the backend, let the api called method handle this)
    const dataToSend = {
      selectedTemplate: this.state.selectedTemplate,
      selectedParameters: values,
    };
    axios.get(apiUrl + '/api/add-parameter', { params: dataToSend })
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          alert("Added Parameter(s) Successfully");
          // update the selectedTemplate with itself to re-render the table
          this.handleChange(this.state.selectedTemplate, { name: "selectedTemplate" });
        }
        else {
          alert("Template OR Parameter with this name doesn't exist!")
        }
      })
      .catch(error => {
        // Handle errors during this request
        console.error('Error:', error);
      });
  }

  toggleAddParamModal = () => {
    this.setState(prevState => ({
      showAddParamModal: !prevState.showAddParamModal
    }));
  }

  displayAddParamModal = () => {
    // show the add param modal
    this.setState({
      showAddParamModal: true
    });
  }

  toggleRenameTemplateModal = () => {
    this.setState(prevState => ({
      showRenameTemplateModal: !prevState.showRenameTemplateModal
    }));
  }

  displayRenameTemplateModal = () => {
    // show the rename template modal
    this.setState({
      showRenameTemplateModal: true
    });
  }

  handleRenameTemplate = (newName) => {
    // Hide the rename template modal "POP UP" window
    this.setState({
      showRenameTemplateModal: false
    });
    const dataToSend = {
      selectedTemplate: this.state.selectedTemplate,
      newTemplateName: newName,
    };
    axios.get(apiUrl + '/api/rename-template', { params: dataToSend })
      .then(response => {
        if (response.data.status === "success") {
          // Handle the received data
          alert("Template Renamed Successfully")
          this.refreshData();
        }
        else if (response.data.status === "name taken"){
          alert("Desired Template Name Already Reserved!")
        }
        else {
          alert("Template with this name doesn't exist")
        }
      })
      .catch(error => {
        // Handle errors during this request
        console.error('Error:', error);
      });
  }

  render() {
    const { availableTemplates, data } = this.state;
    return (
      <div>
        <h1 class="page-header"><center>Parameters Templates Builder</center></h1>
        <br></br>
        <div className="form-wrapper">

          <Row className='mt-1'>
            <Col sm={12}>
              <Form.Label class="field-header">New Template Name</Form.Label>
            </Col>
          </Row>

          <Row className='mt-1'>
            <Col sm={12}>
              <Form.Control className="field-body" type="text" placeholder="" name="name" value={this.state.name} onChange={this.handleInputChange} />
            </Col>
          </Row>
          <br></br>

          <Row className='mt-1'>
            <Col sm={12}>
              <Form.Label class="field-header">Select Parameters</Form.Label>
            </Col>
          </Row>

          <Row className='mt-1'>
            <Col sm={12}>
              <span style={{ width: "100%" }}
                class="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
              >

                <ReactSelect
                  noOptionsMessage={() => "No parameters available!"}
                  options={this.state.parameterOptions.map((parameter) => ({
                    label: parameter.parameter_name,
                    value: parameter.parameter_id,
                  }))}
                  isMulti
                  className="field-body"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{
                    Option
                  }}
                  onChange={this.handleParametersSelection}
                  allowSelectAll={true}
                  value={this.state.paramsSelected}
                  onInputChange={this.handleParamsListInputChange}
                  inputValue={this.state.paramsSearchKeyword}
                />
              </span>
            </Col>
          </Row>
        </div>

        <Row>
          <Col sm={2}>
            <CustomButton onClick={this.createTemplate}>Create Template</CustomButton>
          </Col>

          <Col sm={2}>
            <CustomButton onClick={this.clearData}>Clear Data</CustomButton>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>

        <div className="form-wrapper">

          <Row className='mt-1'>
            <Col sm={12}>
              <Form.Label class="field-header">Check Available Templates</Form.Label>
            </Col>
          </Row>



          <Row className='mt-1'>
            <Col sm={12}>
              <span style={{ width: "100%" }}
                class="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
              >
                <ReactSelect
                  noOptionsMessage={() => "No templates available!"}
                  options={availableTemplates.map((template) => ({
                    label: template,
                    value: template,
                  }))}
                  className="field-body"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={true}
                  components={{
                    Option
                  }}
                  onChange={(selectedOption) => this.handleChange(selectedOption, { name: "selectedTemplate" })}
                  allowSelectAll={false}
                  value={this.state.selectedTemplate}
                />
              </span>
            </Col>
          </Row>
          <br></br>

        </div>
        <br></br>
        <Row>
          <Col sm={2}>
            <CustomButton onClick={this.deleteTemplate}>Delete Template</CustomButton>
          </Col>

          <Col sm={2}>
            <CustomButton onClick={this.refreshData}>Refresh Data</CustomButton>
          </Col>
          <Col sm={2}>
          <CustomButton onClick={this.displayAddParamModal}>Add Parameter</CustomButton>
          </Col>
          <Col sm={2}>
          <CustomButton onClick={this.displayRenameTemplateModal}>Rename Template</CustomButton>
          </Col>
        </Row>
        <br></br>
        <br></br>
        {this.state.selectedTemplate !== "" && data.length > 0 && (
          <DataTable data={data} handle_remove_parameter={this.handleRemoveParameter}/>
        )}
        <Row>
          <Col sm={2}>
            {(this.state.selectedTemplate !== "" && this.state.parameterOptions.length > 0) ?
            <AddParameterModal
              showModal={this.state.showAddParamModal}
              onHide={this.toggleAddParamModal}
              inputParameterOptions={this.state.parameterOptions}
              parentHandleModalParameter={this.handleModalParameterSelection}
            />
            : null}
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            {(this.state.selectedTemplate !== "") ?
            <RenameTemplateModal
              showModal={this.state.showRenameTemplateModal}
              onHide={this.toggleRenameTemplateModal}
              parentRenameTemplate={this.handleRenameTemplate}
            />
            : null}
          </Col>
        </Row>

      </div>
    );
  }
}

