import React from 'react';
import '../styles/MultiStepForm.css';
import { Form, Col, Row, Button } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import getApiUrl from './config.js';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import VisualizationModal from './VisualizationModal';
import ReactSlider from 'react-slider';
import '../styles/slider.css';
const apiUrl = getApiUrl();


/*
    To Change: 
    - Remove the available sessions and session search element which is disabled. 
    (this is not session search this is searching for model objects? or more sepcifically pickle files.)
    - Add grouping for plots of the same model
*/

const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
};
  
function CustomButton({ children, onClick }) {
    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };
    return (
      <Button variant="outline-dark" className="custom-button" onClick={handleClick}>
        {children}
      </Button>
    );
}

export default class DSLab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedModel:"",
          s3URL1: "",
          s3URL2: "",
          availableModels: [],
          availableSignals: [],
          selectedSignal:"",
          selectedTimestamps: [], // selectedTimestamps: [min timestamp selected, max timestamp selected]
          availableTimestamps: 0, // availableTimestamps: num of timestamps available - 1
          npPlotsX: [],
          npPlotsY: [],
          npThresholds: [],
          npTimestamps: [],
          gaussianPlots: [],
          modelLoading: false,
          modelPlot:null,
          requestedPlot: false,
          plotLoading: false,
        };
    }
    
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleChange = (selectedOption, { name }) => {
        this.setState({ [name]: selectedOption });
    };

    get_timestamps = () => {
        this.setState({
            availableTimestamps: 0,
            selectedTimestamps: [],
        });
        if(this.state.selectedSignal !== ''){
            // load the timestamps for the selected signal
            const dataToSend = {
                selectedSignal: this.state.selectedSignal,
            };
            axios.get(apiUrl + '/api/dslab-LoadAvailableTimestamps', { params: dataToSend }) 
            .then(response => {
            if (response.data.status === "success") {
                this.setState({
                    availableTimestamps: response.data.availableTimestamps ,
                    selectedTimestamps: [0, response.data.availableTimestamps],
                });
            }
            else { 
                alert("Unable to find the timestamps for the selected signal.")
            }
            })
            .catch(error => {
            console.error('Error:', error);
            });
        }
        else{
            alert("Please select a signal first.")
        }
    };

    handleSliderChange = (values) => {
        this.setState({selectedTimestamps: values});
    };

    // load in all the available sentry detector models (NOT SESSIONS!!)
    // this method loads in a list of paths for available sentry_detector_ml directory
    get_available_models = (e) => {
        if(this.state.availableModels.length <= 0){
            // Fetch the parameter options from the backend JSON file
            axios.get(apiUrl + '/api/dslab-LoadAvailableModels')
            .then(response => {
                if (response.data.status === "success") {
                // Handle the received data
                this.setState({
                    availableModels: response.data.availableModels,
                });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    };

    load_model = () => {
        // if model selected then load it, otherwise raise alert
        if ((this.state.s3URL1 === '') || (this.state.s3URL2 === '')){
            alert("Please select a model first!")
        }
        else{
            this.setState({
                availableSignals: [],
                selectedSignal: "",
                availableTimestamps: 0,
                selectedTimestamps: [],
                modelLoading: true,
                npPlotsX: [],
                npPlotsY: [],
                npThresholds: [],
                npTimestamps: [],
                gaussianPlots: [],
                modelPlot:null,
                requestedPlot: false,
                plotLoading: false,
            });
            const dataToSend = {
                detector_path: this.state.s3URL1,
                standardized_path: this.state.s3URL2,
            };
            axios.get(apiUrl + '/api/dslab-loadModel', { params: dataToSend }) 
            .then(response => {
            if (response.data.status === "success") {
                this.setState({
                    availableSignals: response.data.availableSignals,
                    modelLoading: false,
                });
            }
            else { 
                this.setState({
                    modelLoading: false,
                });
                alert("Unable to find the model. Check provided details.")
            }
            })
            .catch(error => {
            console.error('Error:', error);
            });
        }
    }

    visualize_model = () => {
        if(this.state.selectedTimestamps.length > 0 && this.state.selectedSignal !== ""){
            this.setState({
                requestedPlot: true,
                plotLoading: true
            });
            // pass the selected timestamps and selected signal to the backend
            const dataToSend = {
                selectedSignal: this.state.selectedSignal,
                selectedTimestamps: this.state.selectedTimestamps,
            };
            axios.get(apiUrl + '/api/dslab-getModelData', { params: dataToSend }) 
            .then(response => {
            if (response.data.status === "success") {
                this.setState({
                    npPlotsX: response.data.np_data_x,
                    npPlotsY: response.data.np_data_y,
                    npThresholds: response.data.np_data_thresholds,
                    npTimestamps: response.data.np_data_timestamps,
                    gaussianPlots: response.data.gaussian_data,
                    plotLoading: false,
                });
                
            }
            else { 
                this.setState({
                    plotLoading: false
                });
                alert("Unable to fetch data. Check provided details.")
            }
            })
            .catch(error => {
            console.error('Error:', error);
            });
        }
        else{
            alert("Please select a model, signal and timestamp.")
        }
    }

    render() {
      return (
        <div>
            <h1 class="page-header"><center>Data Science Labs</center></h1>
            <p>Note: Min Max Distribution Models are not currently supported by the visualizer.</p>
            
            <div className="form-wrapper">
            <Row>
                <Col sm={4}>
                <Form.Label class="field-header">Input sentry_detector_ml Model:</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                  <Form.Control className="field-body2" type="text" placeholder="s3://bba-operationalzone-predictive-maintenance-project/data/04_condmon/local_run/USERNAME/ATA#/FLIGHTPHASE/sentry_detector_ml/model.pickle/2023-10-19T16.40.14.180Z/model.pickle" name="s3URL1" value={this.state.s3URL1} onChange={this.handleInputChange} />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                <Form.Label class="field-header">Input standardized_object Model:</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                  <Form.Control className="field-body2" type="text" placeholder="s3://bba-operationalzone-predictive-maintenance-project/data/04_condmon/local_run/USERNAME/ATA#/FLIGHTPHASE/standardized_object/model.pickle/2023-10-19T16.49.52.247Z/model.pickle" name="s3URL2" value={this.state.s3URL2} onChange={this.handleInputChange} />
                </Col>
            </Row>
            <p></p>
            <Row>
                <Col sm={4}>
                <CustomButton onClick={this.load_model}>Load Model</CustomButton>
                </Col>
                <Col sm={1}>
                <div> {(this.state.modelLoading) ?
                    <Spinner style={{
                      marginTop: 100,
                      marginLeft: 100,
                      marginBottom: 100,
                    }}
                      as="span"
                      animation="grow"
                      size="lg"
                      variant="dark"
                      role="status"
                      aria-hidden="true"
                    /> : null}
                </div>
                </Col>
            </Row>
            {(this.state.availableSignals.length > 0) ?
            <div>
            <Row>
                <Col sm={4}>
                <Form.Label class="field-header">Available Signals</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                {(
                <span style={{ width: "100%" }}
                    class="d-inline-block"
                    data-toggle="popover"
                    data-trigger="focus"
                >
                <ReactSelect
                    noOptionsMessage={() => "No signals available!"}
                    options={this.state.availableSignals}
                    className="field-body"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={true}
                    components={{
                        Option
                    }}
                    onChange={(selectedOption) => this.handleChange(selectedOption, { name: "selectedSignal" })}
                    allowSelectAll={false}
                    value={this.state.selectedSignal}
                    />
                </span>
                )}
                </Col>
            </Row>
            <p></p>
            <Row>
                <Col sm={4}>
                    <CustomButton onClick={this.get_timestamps}>Load Signal</CustomButton>
                </Col>
            </Row>
            </div>
            : null}
            {(this.state.availableTimestamps > 0) ?
            <div>
            <Row>
                <Col sm={4}>
                <Form.Label class="field-header">Available Timestamps</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <ReactSlider 
                    className="horizontal-slider"
                    trackClassName="slider-track"
                    thumbClassName="slider-thumb"
                    defaultValue={[0,this.state.availableTimestamps]}
                    min={0}
                    max={this.state.availableTimestamps}
                    minDistance={0}
                    pearling
                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    onAfterChange={state => this.handleSliderChange(state)}
                    />
                </Col>
            </Row>
            <p></p>
            <p></p>
            </div>
            : null}
            
            </div>
            <Row>
                <Col sm={4}>
                <CustomButton onClick={this.visualize_model}>Visualize Model</CustomButton>
                </Col>
            </Row>
    
            <div>
            {(this.state.requestedPlot) ?
                <div className='plots-wrapper'> {(this.state.plotLoading) ?
                    <Spinner style={{
                      marginTop: 200,
                      marginLeft: 200,
                      marginBottom: 200,
                    }}
                      as="span"
                      animation="grow"
                      size="lg"
                      variant="dark"
                      role="status"
                      aria-hidden="true"
                    /> : <VisualizationModal npPlotsData={[this.state.npPlotsX, this.state.npPlotsY, this.state.npTimestamps, this.state.npThresholds]} gaussianPlotsData={this.state.gaussianPlots} selectedSignal={this.state.selectedSignal['label']} />}
                </div>
                :null}
            </div>
        </div>
      );
    }
  }