import React, { useState } from 'react';
import MyFormModal from './MyFormModal';
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { RiDashboardLine } from 'react-icons/ri';
import '../styles/sidebar.css';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';

const Sidebar = ({ showSidebar }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };

  const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
      <li className="nav-item">
        <Link
          to={to}
          {...props}
          className={`nav-link ${isActive ? "active" : ""}`}
        >
          {children}
        </Link>
      </li>
    );
  }

  return (
    <>

      <div
        className={`col-md-2 col-lg-1 d-md-block sidebar collapse ${showSidebar ? "show" : ""
          }`}
        style={{ display: 'flex', height: '97vh', overflow: 'scroll initial' }}
      >
        <CDBSidebar textColor="#fff" backgroundColor="#333">

          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-2x" style={{ color: "#d19000" }}></i>}>

            <div className="text-center mb-5" style={{ paddingTop: "5vh" }}>
              <RiDashboardLine size={48} color="#007bff" className="mb-2" />
              <h2 >DataProbe</h2>
            </div>
          </CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <CustomLink to="/data-loader">

                <CDBSidebarMenuItem icon="chart-line" style={{ color: "#d19000", fontSize: "20px", marginRight: "20px" }}>
                  <div className="menu-item-content">
                    <h5 className="menu-item-title">Data Loader</h5>
                  </div>
                </CDBSidebarMenuItem>
              </CustomLink>

              <CustomLink to="/templates-builder">
                <CDBSidebarMenuItem icon="th-large" style={{ color: "#d19000", fontSize: "20px", marginRight: "20px" }}>
                  <div className="menu-item-content">
                    <h5 className="menu-item-title">Templates Builder</h5>
                  </div>
                </CDBSidebarMenuItem>
              </CustomLink>

              <CustomLink to="/metrics">
                <CDBSidebarMenuItem icon="table" style={{ color: "#d19000", fontSize: "20px", marginRight: "20px" }}>
                  <div className="menu-item-content">
                    <h5 className="menu-item-title">Metrics</h5>
                  </div>
                </CDBSidebarMenuItem>
              </CustomLink>

              <CustomLink to="/ds-lab">
                <CDBSidebarMenuItem icon="book" style={{ color: "#d19000", fontSize: "20px", marginRight: "20px" }}>
                  <div className="menu-item-content">
                    <h5 className="menu-item-title">Data Science Lab</h5>
                  </div>
                </CDBSidebarMenuItem>
              </CustomLink>

              <CustomLink to="/settings">
                <CDBSidebarMenuItem icon="user" style={{ color: "#d19000", fontSize: "20px", marginRight: "20px" }}>
                  <div className="menu-item-content">
                    <h5 className="menu-item-title">Settings</h5>
                  </div>
                </CDBSidebarMenuItem>
              </CustomLink>

              <CustomLink to="/help">
                <CDBSidebarMenuItem icon="info-circle" style={{ color: "#d19000", fontSize: "20px", marginRight: "20px" }}>
                  <div className="menu-item-content">
                    <h5 className="menu-item-title">Help</h5>
                  </div>
                </CDBSidebarMenuItem>
              </CustomLink>

            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
              className="fa fa-envelope"
              style={{
                color: "#d19000",
                fontSize: "40px",
                padding: '20px 5px',
                cursor: 'pointer',
              }}
              onClick={handleClick}
            />
          </CDBSidebarFooter>
          <MyFormModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />

        </CDBSidebar>
      </div>

    </>
  );
};

export default Sidebar;