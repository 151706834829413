import React, { useState, useEffect } from 'react';
import news from './news.jpg'

const Puzzle = () => {
  const [showBackground, setShowBackground] = useState(true);
  const [showText, setShowText] = useState(false);
  const [fieldValues, setFieldValues] = useState([-1, -1, -1, -1, -1]);
  const [percentage, setPercentage] = useState(0);
  const [solved, setSolved] = useState(false);
  const [count, setCount] = useState(0);

  const handleChange = (index, value) => {
    const newValues = [...fieldValues];
    newValues[index] = value;
    setFieldValues(newValues);
};

const isButtonDisabled = () => {
  return fieldValues.some(value => value !== 0 && value !== 1);
};

const calculatePercentage = () => {
  const weights = [-10, 50, 30, 50, -20]; // Adjust weights accordingly
  let total = 0;
  let count = 0;

  for (let i = 0; i < fieldValues.length; i++) {
      if (fieldValues[i] === 1) continue; // Skip if field value is 0

      const weight = weights[i];
      total += weight;
      count++; // Increment count of fields with value 1
  }

  if (count === 0) {
      setPercentage(0);
  } else {
      let newPercentage = (total / (weights.reduce((acc, curr) => acc + curr, 0))) * 100;
      newPercentage = Math.min(Math.max(newPercentage, 0), 100); // Normalize between 0 and 100
      setPercentage(newPercentage.toFixed(2));

      if (newPercentage >= 100) { // Check if percentage is greater than or equal to 100
          setSolved(true);
      }
  }

  setCount(prevCount => prevCount + 1);
};


    useEffect(() => {
        // Set timeout to hide background and show text after 5 seconds
        const timer = setTimeout(() => {
            setShowBackground(false);
            setShowText(true);
        }, 6000);

        // Clean up the timer
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {showBackground && (
                <div style={{ backgroundImage: `url(https://i.gifer.com/2yqT.gif)`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} />
            )}

            {/* Image above center text */}
            {showText && (
                <img src={news} alt="Your Image" style={{ position: 'absolute', top: '20px', left: '50%', transform: 'translateX(-50%)', maxWidth: '100%', maxHeight: '100%', zIndex: 1 }} />
            )}

            {/* Text box in the center */}
            {showText && (
                <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', borderRadius: '10px', maxWidth: '80%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                    <h1 style={{ fontFamily: 'Arial, sans-serif', fontSize: '36px', fontWeight: 'bold', marginBottom: '20px' }}>Baby will be joining Rijul's family in May 2024</h1>
                </div>
            )}

            {/* Another component at the bottom */}
            {showText && (
                <div style={{ position: 'absolute', bottom: '20px', textAlign: 'center', width: '100%', zIndex: 2 }}>
                    {/* This component can be another React component or just a paragraph */}
                    <h1> Now let's solve the last challenge</h1>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
                        Considering baby's gender prediction problem as classification problem. Consider each member's prediction as model's prediction.
                        We can solve this problem strategically as a team with best case complexity (1). Use label (0 or 1) for baby girl or boy. The exact label for boy/girl is not unveiled here.
                        These predictors can have different weights (performance factor) though. You will get the answer when you achieve 100% precision.
                    </p>
                </div>
            )}


           {/* Input fields with button */}
           {showText && (
                <div style={{ textAlign: 'center' }}>
                    {/* This component can be another React component or just a paragraph */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1200px' }}>
                        {fieldValues.map((value, index) => (
                            <div key={index} style={{ marginRight: '10px' }}>
                                <input type="number" min="0" max="1" value={value} onChange={e => handleChange(index, parseInt(e.target.value))} />
                            </div>
                        ))}
                        <button onClick={calculatePercentage} disabled={isButtonDisabled()}>Calculate</button>
                    </div>
                </div>
            )}

            {/* Percentage field */}
            {showText && (
                <div style={{ textAlign: 'center' }}>
                    <p>Precision: {percentage}%</p>
                </div>
            )}

            {/* Solved message */}
            {solved && (
                <div style={{ textAlign: 'center' }}>
                    <p>You solved! Baby will be a girl 😃</p>
                </div>
            )}

            {/* Count display */}
            {showText && (
                <div style={{ textAlign: 'center' }}>
                    <p>Count: {count}</p>
                </div>
            )}
        </div>
    );
};

export default Puzzle;