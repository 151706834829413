import { useState } from 'react';
import axios from 'axios';
import { RiDashboardLine } from 'react-icons/ri';
import React from 'react';
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import getApiUrl from './config.js';
const apiUrl = getApiUrl();

const Login = ({ handleLogin }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (userName === "" || password === "") {
      setError('Please provide Username/Password!');
    }

    else {
      // Send the entered information to the Flask application for validation
      try {
        const dataToSend = {
          userName: userName,
          password: password
        };

        axios.get(apiUrl + '/api/login', { params: dataToSend })
          .then(response => {
            if (response.data.status === "success") {
              handleLogin();
            }
            else {
              setError('Invalid credentials');
            }
          })
          .catch(error => {
            setError('A problem occurred while performing login. \nPlease contact the administrator.');
          });
      } catch (error) {
        // Handle errors during this request
        setError('An error occurred during validation');
        console.error(error);
      }
    }
  };

  return (
    <>
      <Container className="centered">
        <Row>
          <Col md={12} lg={12} x1={14} className="m-md-auto">
            <Card className="shadow-sm mb-2-sm">
              <Card.Body className="p-4">
                <div className="text-center mb-5">
                  <RiDashboardLine size={48} color="#007bff" className="mb-2" />
                  <h2>DataProbe</h2>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formGroupUserName">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter User ID"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  {error && (
                    <div className="text-danger mb-3" style={{ whiteSpace: 'pre-line' }}>
                      {error}
                    </div>
                  )}
                  <Button className="w-100 py-3 mt-3" type="submit">
                    Login
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>

    </>
  );
};

export default Login;
